import classes from "./Card.module.css";

const Card = ({ href, src, alt, title }) => {
  return (
    <a className={classes.link} href={href}>
      <div className={classes.card}>
        <img className={classes.image} src={src} alt={alt} />
        <div className={classes.title}>{title}</div>
      </div>
    </a>
  );
};

export default Card;
