import logo from "./assets/logo.png";
import Card from "./Card";
import { cards } from "./cards";
import classes from "./App.module.css";

const App = () => {
  return (
    <div className={classes.main}>
      <div className={classes.logo}>
        <img
          className={classes.logoImage}
          style={{ width: "120px" }}
          src={logo}
          alt="sakuramancer logo"
        />
        <h1>Sakuramancer</h1>
      </div>
      <div className={classes.links}>
        {Object.values(cards).map(({ id, href, src, alt, title }) => (
          <Card key={id} href={href} src={src} alt={alt} title={title} />
        ))}
      </div>
      <span className={classes.attribution}>樱花 by 画画的summer</span>
    </div>
  );
};

export default App;
