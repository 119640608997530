import twilightImage from "./assets/twilight.png";
import dndImage from "./assets/alliance.png";

export const cards = {
  twilight: {
    id: "twilight",
    href: "https://twilight.skr.ge",
    src: twilightImage,
    alt: "Twilight Imperium 4th edition",
    title: "Сумерки Империи: Трекер",
  },
  dnd: {
    id: "dnd",
    href: "https://dnd.skr.ge",
    src: dndImage,
    alt: "Союз ночного неба",
    title: "Редея. Легенда о Драконе Кошмаров",
  },
};
